<template>
    <img
        :src="src || defaultAvatar"
        class="avatar"
        alt="Аватар"
        :style="style"
    >
</template>

<script>
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';
    export default {
        name: "Avatar",
        props: {
            src: {
                type: String,
                required: false
            },
            bdrs: {
                required: false,
                default: '50%'
            },
            w: {
                required: false,
                default: '40px'
            },
            h: {
                required: false,
                default: '40px'
            }
        },
        data() {
            return {
                defaultAvatar,
                style: {
                    height: this.h,
                    width: this.w,
                    minHeight: this.h,
                    minWidth: this.w,
                    borderRadius: this.bdrs
                }
            }
        }
    }
</script>

<style lang="sass" scoped>
</style>
